<template>
  <div class="py-3">
    <nav class="w-100 bg-primary menubar">
      <ul>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'av' } }" exact>Avatar</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'bt' } }" exact>{{$t('tournaments')}}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'bd' } }" exact>{{$t('divisions')}}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'be' } }" exact>{{$t('teams')}}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'bu' } }" exact>{{$t('players')}}</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ShopPurchasedProducts', params: { lang: lang, console: console, type: 'ca' } }" exact>{{$tc('card', 2) | toCapitalize }}</router-link>
        </li>
      </ul>
    </nav>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3" v-for="product in products" :key="product.id">
          <div class="card">
            <img :src="product.image" :alt="product.name" class="card-img-top">
            <div class="card-body">
              <h5 class="card-title text-center">{{ product.name }}</h5>
              <p class="card-text mb-0"><small>{{ $t('boughtOn') }}: {{ product.date_purchase }}</small></p>
              <p class="card-text mb-0"><small>{{ $t('duration') }}: {{ product.duration }} {{ $tc('month', 2) }}</small></p>
              <p class="card-text mb-3"><small>{{ $t('beatOn') }}: {{ product.due_date }}</small></p>
              <b-button v-if="!product.is_used" block variant="primary" @click="useProduct(product)">{{ $t('use') }}</b-button>
              <b-button v-else block variant="danger" @click="removeProduct(product)">{{ $t('remove') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      products: []
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user'
    ])
  },
  watch: {
    '$route' () {
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    generatePayload (product) {
      const payload = {
        type: product.type,
        product_id: product.id
      }
      if (product.type === 'BT' || product.type === 'BD' || product.type === 'BE') {
        payload.for = product.for
      }
      return payload
    },
    useProduct (product) {
      const payload = this.generatePayload(product);
      const path = `auth/${this.lang}/console/${this.console}/shop/product/use`
      this.$axios.post(path, payload).then(response => {
        this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
        this.products = this.products.map(p => {
          if (p.id === product.id) {
            p.is_used = true
          } else {
            p.is_used = false
          }
          return p
        })
      })
    },
    removeProduct (product) {
      const payload = this.generatePayload(product);
      const path = `auth/${this.lang}/console/${this.console}/shop/product/remove`
      this.$axios.post(path, payload).then(response => {
        this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
        this.products = this.products.map(p => {
          if (p.id === product.id) {
            p.is_used = false
          }
          return p
        })
      })
    },
    fetchData () {
      const type = this.$route.params.type
      const path = `auth/${this.lang}/console/${this.console}/shop/purchased-products/${type}`
      this.$axios.get(path).then(response => {
        this.products = response.data.data
      })
    }
  }
}
</script>
